@charset "utf-8";
/* CSS Document */

/* 基本設定-------------------------------------------*/
@import "import.scss";

a {
  @include linkBase;
  outline:none;
  position: relative;
  display: inline-block;
}
a:hover {
  color: $pointColor;
  @include hoverBase;
}
a img,
a svg {
  @include linkBase;
  outline:none;
}

div {
  outline:none;
  word-break: break-word;
}

img {
  max-width: 100%;
  height: auto;
  outline:none;
}

svg {
  backface-visibility: hidden;
  transform: translateZ(0);
}

div,li,header,footer,dt,dd,section {
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='submit'],
input[type='button'],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
    display: none;
}

.pc-view {
  display: none !important;
}
.sp-view {
  display: block !important;
}

@media screen and (min-width:769px){
  .pc-view {
    display: block !important;
  }
  .sp-view {
    display: none !important;
  }
}

/*-- Base --*/
html {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  color: $basefontColor;
  background: #FFF;
  line-height: 1.6;
  @include baseFont;
  font-size: 12px;
  width: 100%;
  word-wrap: break-word;
  letter-spacing: 0.02em;
  // font-feature-settings: "palt";
  padding-top: 41px;
}
@media screen and (min-width:769px){
  body {
    padding-top: 60px;
  }
  a[href*="tel:"] {
    // pointer-events: none;
    // cursor: default;
    text-decoration: none;
  }
}

/*-- HTML5 elements --*/
article, aside, details, figcaption, figure, footer, header, nav, section, summary {
  display: block;
  margin: 0;
  padding: 0;
}
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.clearfix {
  min-height: 1px;
}

* html .clearfix {
  height: 1px;
  /*\*//*/
  height: auto;
  overflow: hidden;
  /**/
}

strong {
  font-weight: bold;
}

section,article {
  position: relative;
}

::selection {
  background: #9c9c9c;
  color: #fff;
}

::-moz-selection {
  background: #9c9c9c;
  color: #fff;
}


/* 細かいパーツ-------------------------------------------*/
#page {
  position: relative;
  padding-top: 61px;
}
@media screen and (min-width:769px){
  #page {
    padding-top: 102px;
  }
}

.flex-wrapper {
  display: flex;
}

.box-inner {
  margin: 0 20px;
  position: relative;
}

@media screen and (min-width:769px){
  .box-inner {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
  }
  .pc-flex {
    display: flex;
  }
}

//英語フォント
.font-en {
  @include fontLatin;
}

.icon-svg {
  position: absolute;
  width: 0;
  height: 0;
}

// ボタン全般
.btn {
  @include fontLatin;
  @include fontBold;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  padding: 8px;
  a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: $mainBlkColor;
    border-radius: 25px;
    padding: 8px 20px;
    .txt {
      margin-left: auto;
    }
  }
}
@media screen and (min-width:769px){
  .btn {
    a {
      &:hover {
        opacity: 1;
        background: $pointColor;
      }
    }
  }
}
//一覧を見るボタン
.btn-more {
  width: 62%;
}
@media screen and (min-width:769px){
  .btn-more {
    width: 220px;
    margin: 0 auto;
    a:hover {
      .icon-arrow01 {
        transform: translateX(5px);
      }
    }
  }
}

//矢印アイコン
.icon-arrow01 {
  width: 22px;
  display: flex;
  transition: all 0.3s ease;
  margin-left: auto;
  &:before {
    content: "";
    width: 22px;
    height: 9px;
    background: url(/assets/images/common/icon_arrow01.svg) no-repeat;
    // border-bottom: solid 2px #fff;
    // border-right: solid 2px #fff;
    // transform: skew(45deg);
    transition: all 0.3s ease;
  }
}
@media screen and (min-width:769px){
  .icon-arrow01 {
  }
}

.icon-arrow02 {
  position: relative;
  width: 12px;
  height: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.3s ease;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    border-top: 1px solid $pointColor;
    border-right: 1px solid $pointColor;
    transform: rotate(45deg);
    transition: all 0.3s ease;
  }
}

// 閉じるアイコン
.icon-close {
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 16px;
    height: 16px;
    background: url(../images/common/icon_close.svg) no-repeat;
    background-size: contain;
  }
}

// NEWアイコン
.icon-new {
  position: absolute;
  top: -13px;
  left: -13px;
  background: url(../images/common/iconbg_new.svg) no-repeat;
  background-size: contain;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  @include fontMedium;
  @include fontLatin;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  z-index: 1;
  @media screen and (min-width:769px){
    .icon-new {
      position: absolute;
      top: -30px;
      left: -30px;
      width: 60px;
      height: 60px;
      font-size: 16px;
    }
  }
}

//小さいテキスト
.font-s {
  font-size: 10px;
}
@media screen and (min-width:769px){
  .font-s {
    font-size: 12px;
  }
}

.page-bd {
  //padding-bottom: 60px;
}
@media screen and (min-width:769px){
  .page-bd {
    //padding-bottom: 95px;
  }
}

br.sub-view {
  display: none;
}

.bgW {
  background: #fff !important;
}

.mds-ttl {
  @include mds-type01SP;
}
@media screen and (min-width: 769px) {
  .mds-ttl {
    @include mds-type01PC;
  }
}
.mds-s {
  color: $pointColor;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
}

.noinfo {
  padding: 0 0 20px 30px;
  color: #888;
  font-size: 16px;
}
@media screen and (min-width: 769px) {
  .noinfo {
    font-size: 20px;
    padding-left: 60px;
  }
}

.tel-pcolor {
  a {
    color: #017A8A;
  }
}
.contents-tbl {
  .tel {
    a {
      color: #333;
    }
  }
}
.deco {
  > div {
    position: absolute;
    z-index: -1;
  }
}

/* ショップ詳細（小）-------------------------------------------*/
.shop-info-box {
  display: grid; 
  grid-template-rows: 0fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background: #fff;
  border: 2px solid #fc5912;
  padding: 20px;
  margin-bottom: 60px;
  @media screen and (min-width: 769px) {
    padding: 40px 6vw;
    grid-template-columns: 360px 1fr;
    grid-template-rows: 1fr 2fr;
    margin-bottom: 80px;
  }
  &.archive {
    margin-bottom: 30px;
  }
  .shop-img {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
    padding: 0 16px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 769px) {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-row-end: 3;
      grid-column-end: 2;
      padding: 0;
      height: 320px;
      width: 320px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 320px;
        width: 320px;
      }
      img {
        width: auto;
        max-height: 100%;
        height: auto;
      }
    }
}
.shop-info01 {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
    margin-bottom: 20px;
    .floor,
    .category {
      background: $mainBlkColor;
      border-radius: 12px;
      font-size: 11px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      padding: 3px 10px;
      margin-bottom: 5px;
      display: table;
    }
    .shopname {
      font-weight: bold;
      font-size: 16px;
    }
    @media screen and (min-width: 769px) {
      grid-row-start: 1;
      grid-column-start: 2;
      grid-row-end: 2;
      grid-column-end: 3;
      .floor,
      .category {
        display: inline-block;
        margin-right: 10px;
        padding: 3px 20px;
      }
      .shopname {
        font-size: 18px;
      }
    }
}
.shop-info02 {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 3;
    border-radius: 10px;
    background: #f5f5f5;
    padding: 20px;
    padding-bottom: 10px;
    dl {
      dt {
        font-weight: bold;
      }
      dd {
        margin-bottom: 14px;
      }
    }
    @media screen and (min-width: 769px) {
      grid-row-start: 2;
      grid-column-start: 2;
      grid-row-end: 3;
      grid-column-end: 3;
      margin-bottom: auto;
      dl {
        display: flex;
        flex-wrap: wrap;
        dt {
          font-weight: bold;
          width: 100px;
          font-size: 14px;
        }
        dd {
          margin-bottom: 14px;
          width: 82%;
        }
      }
    }
  }
}

/* アニメーション-------------------------------------------*/
.fade-element {
  opacity: 0;
  transform: translateY(20px);
  transition: all .5s;
}

.fadein {
  opacity: 1 !important;
  transform: translateY(0);
}

/* ナビゲーションボタン-------------------------------------------*/
.icon-nav {
  display: flex;
  flex-wrap: wrap;
  li {
    a {
      width: 100%;
    }
    .circle-wrapper {
      width: 18vw;
      margin: 0 auto;
    }
    .circle {
      position: relative;
      border-radius: 50%;
      width: 100%;
      padding-top: 100%;
      background: #ffbf6a;
      text-align: center;
      transition: all 0.3s ease;
    }
    .icon-btn {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-base {
        width: 100%;
      }
    }
  }
  .icon {
    fill: #4D4D4D;
    transition: all 0.3s ease;
  }
}
@media screen and (min-width: 769px) {
  .icon-nav {
    li {
      a {
        &:hover {
          opacity: 1;
          .circle {
            background: $pointColor;
          }
          .icon {
            fill:#fff;
          }
        }
      }
    }
  }
}

/* テロップ-------------------------------------------*/
.telop {
  background: #F5F5F5;
  padding: 10px 20px;
  text-align: center;
  .telop-inner {
    p {
      margin-bottom: 10px;
      font-size: 10px;
    }
    .mds {
      font-weight: bold;
      padding-left: 0;
      text-align: center;
    }
    a {
      text-decoration: underline;
    }
    strong,b {
      font-weight: bold;
    }
    em {
      font-style: italic;
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    table {
      th {
        text-align: center;
        vertical-align: inherit;
        font-weight: bold;
      }
    }
    .fwN, caption, h1, h2, h3, h4, h5, h6, td, th {
      margin: 0;
    }
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.17em;
    }
    h4 {
      font-size: 1em;
    }
    h5 {
      font-size: 0.83em;
    }
    h6 {
      font-size: 0.67em;
    }
    hr {
      display: block;
    }
  }
}
@media screen and (min-width:769px){
  .telop {
    padding: 20px 10px 10px;
    .telop-inner {
      max-width: 1090px;
      margin: 0 auto;
      font-size: 12px;
      .mds {
        font-size: 16px;
        margin-bottom: 2px;
      }
      .txt-box {
        p {
          font-size: 12px;
        }
      }
    }
  }
}

/* 共通ヘッダー-------------------------------------------*/
.cmn-hd {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 98;
  .hd-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 41px;
    padding: 0 10px;
    background: #fff;
    img {
      vertical-align: middle;
    }
  }
  .ttl {
    img {
      vertical-align: initial;
      max-width: 66px;
      max-height: 100%;
    }
    a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
    }
  }
  .ttl-sc {
    text-align: center;
    height: 100%;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
      padding: 5px 0;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 1.5;
    }
    .ttl-l {
      color: $pointColor;
    }
    .ttl-s {
      font-size: 10px;
    }
  }
  .hd-btn {
    display: flex;
    align-items: center;
    // img {
    // }
    .icon-hour {
      width: 26px;
      margin-right: 10px;
    }
    .icon-line {
      width: 26px;
      margin-right: 10px;
    }
    .icon-insta {
      width: 22px;
    }
    .txt {
      display: none;
    }
  }
}
.navbtn {
  width: 50px;
  height: 41px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  p {
    z-index: 1;
    position: relative;
  }
  .hbg-wrapper {
    position: relative;
    width: 25px;
    height: 16px;
    margin: 0 auto;
  }
  span {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 2px;
    width: 100%;
    background-color: $mainBlkColor;
    display: inline-block;
    box-sizing: border-box;
    transition: all 0.3s ease;
    z-index: 1;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 7px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.btn-gnav {
  p {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
  }
}

@media screen and (min-width:769px){
  .cmn-hd {
    .hd-inner {
      align-items: center;
      height: 60px;
      padding: 9px 47px;
    }
    .flex-wrapper {
      margin-right: 145px;
    }
    .logo-sub {
      width: 200px;
    }
    .ttl {
      padding: 0;
      a {
        padding: 0;
      }
      .img01 {
        max-width: 82px;
        margin: 0 auto;
      }
    }
    .ttl-sc {
      a {
      }
      p {
        font-size: 24px;
      }
      .ttl-s {
        font-size: 14px;
      }
    }
    .hd-btn {
      img {
      }
      .icon-hour {
        width: auto;
        margin-right: 15px;
      }
      .icon-line {
      }
      .icon-insta {
      }
      .txt {
        display: inline;
        vertical-align: middle;
        padding-left: 7px;
      }
    }
  }
  .navbtn {
    width: 24px;
    height: 20px;
    margin-left: 22px;
    &:hover {
      opacity: 0.7;
    }
    .hbg-wrapper {
      width: 24px;
      height: 20px;
    }
    span {
      &:nth-of-type(1) {
      }
      &:nth-of-type(2) {
        top: 9px;
      }
      &:nth-of-type(3) {
      }
    }
  }
}

//サブナビ
#hdsub {
  top: -100px;
  transition: all 0.5s ease;
  br.sub-view {
    display: block;
  }
  .hd-inner {
    @media screen and (min-width:769px) {
      padding: 9px 12px 9px 30px;
    }
  }
  .icon-nav {
    align-items: center;
    display: none;
    @media screen and (min-width:769px) {
      display: flex;
      margin: 0 auto;
    }
    .icon-new {
      display: none;
    }
    li {
      margin-right: 20px;
      @media screen and (min-width:769px) {
        margin-right: 20px;
        font-size: 11px;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        display: flex;
        align-items: center;
        &:hover {
          .circle {
            background: transparent;
          }
          .icon {
            fill: $pointColor;
          }
        }
        .txt {
          line-height: 1.2;
        }
      }
      .circle-wrapper {
        margin-right: 8px;
        width: 21px;
      }
      .circle {
        background: transparent;
      }
    }
  }
  .hd-btn {
    @media screen and (min-width:769px) {
      display: none;
    }
  }
}

/* グローバルナビ-------------------------------------------*/
body,html {
  &.fixed {
    overflow: hidden;
  }
}
.nav-contents {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: $pointColor2;
  height: 100vh;
  z-index: 100;
  &.show {
    display: block;
  }
  .navbtn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .inner {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    background: $pointColor2;
    padding: 40px 40px 90px;
  }
  .icon-nav {
    display: block;
    margin-bottom: 20px;
    .icon-new {
      top: -3px;
      left: -22px;
      width: 36px;
      height: 36px;
      @media screen and (min-width:769px) {
        top: -10px;
      }
    }
    a {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
    }
    li {
      margin-bottom: 10px;
      position: relative;
      .circle-wrapper {
        margin: 0;
        margin-right: 10px;
        width: 13.4vw;
        .circle {
          background: #fff;
        }
      }
      &.nav-shopguide {
        .icon-btn {
          width: 20px;
          height: 20px;
        }
      }
      &.nav-shopblog{
        .icon-btn {
          width: 22px;
          height: 18px;
        }
      }
      &.nav-event{
        .icon-btn {
          width: 20px;
          height: 18px;
        }
      }
      &.nav-service{
        .icon-btn {
          width: 20px;
          height: 20px;
        }
      }
      &.nav-access{
        .icon-btn {
          width: 26px;
          height: 11px;
        }
      }
      &.nav-hour{
        .icon-btn {
          width: 20px;
          height: 20px;
        }
      }
      &.nav-card{
        .icon-btn {
          width: 20px;
          height: 18px;
        }
      }
      &.nav-garden{
        .icon-btn {
          width: 20px;
          height: 20px;
        }
      }
      &.nav-recruit{
        .icon-btn {
          width: 24px;
          height: 21px;
        }
      }
    }
  }
  .txt-nav {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
    p {
      margin-bottom: 10px;
    }
  }
  .btn-close {
    width: 70%;
  }
}
@media screen and (min-width:769px){
  .nav-contents {
    top: 0;
    height: 431px;
    .inner {
      padding-top: 50px;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      max-width: 772px;
      margin: 0 auto;
      .icon-nav {
        display: flex;
        flex-wrap: wrap;
        a {
          &:hover {
            opacity: 1;
            .circle {
              background: $pointColor;
            }
          }
        }
        li {
          width: calc(100% / 3);
          margin-bottom: 20px;
          .circle-wrapper {
            width: 50px;
            .circle {
            }
          }
          &.nav-shopguide {
            .icon-btn {
              width: 20px;
              height: 20px;
            }
          }
          &.nav-shopblog{
            .icon-btn {
              width: 22px;
              height: 18px;
            }
          }
          &.nav-event{
            .icon-btn {
              width: 20px;
              height: 18px;
            }
          }
          &.nav-service{
            .icon-btn {
              width: 20px;
              height: 20px;
            }
          }
          &.nav-access{
            .icon-btn {
              width: 26px;
              height: 11px;
            }
          }
          &.nav-hour{
            .icon-btn {
              width: 20px;
              height: 20px;
            }
          }
          &.nav-card{
            .icon-btn {
              width: 20px;
              height: 18px;
            }
          }
          &.nav-garden{
            .icon-btn {
              width: 20px;
              height: 20px;
            }
          }
          &.nav-recruit{
            .icon-btn {
              width: 24px;
              height: 21px;
            }
          }
        }
      }
      .txt-nav {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        p {
          margin: 0 10px;
          font-size: 12px;
        }
      }
      .btn-close {
        width: 200px;
      }
    }
  }
}

/* パンくず-------------------------------------------*/
.breadcrumb-area {
  background: #F5F5F5;
  display: block;
  margin: 0 auto;
  padding: 6px 0;
  width: 100%;
  .breadcrumb {
    margin: 0 30px;
    font-size: 12px;
    li {
      position: relative;
      padding-right: 22px;
      display: inline;
      &:after {
        position: absolute;
        content: "";
        vertical-align: middle;
        transform: rotate(45deg);
        border-top: 1px solid #898888;
        border-right: 1px solid #898888;
        left: auto;
        width: 5px;
        height: 5px;
        right: 7px;
        bottom: 0;
        top: 0;
        margin: auto;
        text-decoration: none;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
  .current {
    a {
      text-decoration: none;
      color: #453932;
    }
  }
}
@media screen and (min-width:769px){
  .breadcrumb-area {
    padding: 15px 0;
    .breadcrumb {
      font-size: 14px;
      margin: 0 auto;
      padding: 0 10px;
      max-width: 1100px;
    }
  }
}

/* コンテンツエリア-------------------------------------------*/
.main-area {
  padding: 20px 0 0;
  overflow: hidden;
  > .deco {
    .deco01 {
      width: 285px;
      top: 0.4%;
      left: -36%;
    }
    .deco02 {
      width: 200px;
      top: -6.5vh;
      right: -9%;
    }
  }
}
@media screen and (min-width:769px){
  .main-area {
    padding: 60px 0 0;
    > .deco {
      .deco01 {
        width: 349px;
        top: 0;
        left: -7.8%;
      }
      .deco02 {
        width: 260px;
        top: -4%;
        left: auto;
        right: 32%;
      }
      .deco03 {
        width: 337px;
        top:3%;
        right: 0;
      }
    }
  }
}

/* 下層タイトル-------------------------------------------*/
.ttl-area {
  background: #FAF9ED;
  padding: 30px 10px;
  position: relative; 
  text-align: center;
}
.ttl-mds {
  @include mds-type01SP;
  line-height: 1;
  z-index: 2;
  padding: 0 20px;
  &.type01 {
    color: #c0de40;
  }
  &.type02 {
    color: #FF9202;
  }
}
@media screen and (min-width:769px){
  .ttl-area {
    padding: 70px 0;
  }
  .ttl-mds {
    @include mds-type01PC;
    max-width: 1088px;
    margin: 0 auto;
    text-align: left;
  }
}

/* 下層背景-------------------------------------------*/
.contents-wrapper {
  border-radius: 0px 40px 0px 0px;
  padding: 40px 0 60px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media screen and (min-width:769px){
    padding: 80px 0 90px;
  }
  &.type01 {
    background: #f6fdd8;
    position: relative;
    .deco {
      .deco01 {
        width: 165px;
        top: 5.3%;
        right: -13%;
      }
      .deco02 {
        width: 211px;
        bottom: 49%;
        left: -24%;
      }
      .deco03 {
        width: 334px;
        bottom: -24vh;
        right: -30%;
      }
    }
    @media screen and (min-width:769px){
      .deco {
        .deco01 {
          width: 320px;
          bottom: auto;
          top: 60%;
          right: auto;
          left: -3.5%;
        }
        .deco02 {
          width: 250px;
          bottom: auto;
          top: 22%;
          left: auto;
          right: 4%;
        }
        .deco03 {
          width: 467px;
          bottom: -21vh;
          right: -13%;
        }
      }
    }
  }
  &.type02 {
    background: #FFEEDD;
    position: relative;
    .deco {
      .deco01 {
        width: 278px;
        top: 5.3%;
        right: -13%;
      }
      .deco02 {
        width: 160px;
        bottom: 49%;
        left: -24%;
      }
      .deco03 {
        width: 175px;
        bottom: 0;
        right: 0;
      }
    }
    @media screen and (min-width:769px){
      .deco {
        .deco01 {
          width: 349px;
          bottom: auto;
          top: 22%;
          left: auto;
          right: 4%;
        }
        .deco02 {
          width: 260px;
          bottom: auto;
          top: 60%;
          right: auto;
          left: -3.5%;
        }
        .deco03 {
          width: 292px;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}

/* 下層白背景-------------------------------------------*/
.contents-box {
  background: #fff;
  padding: 25px 5.2vw;
  position: relative;
  border-radius: 20px;
  @media screen and (min-width: 769px) {
    max-width: 1088px;
    margin: 0 auto;
    padding: 60px 8%;
  }
}

/* 下層パーツ-------------------------------------------*/
.detail-tbl {
  margin-bottom: 20px;
  width: 100%;
  @media screen and (min-width: 769px) {
	  margin-bottom: 60px;
  }
  tbody {
    tr {
      &.name {
        margin-top: 30px;
        display: block;
        @media screen and (min-width: 769px) {
          display: table-row;
		  margin-top: 0;
        }
      }
      th,
      td {
        padding: 15px 20px;
        display: block;
        @media screen and (min-width: 769px) {
          display: table-cell;
		  border-bottom: 3px solid #fff;
        }
      }
      th {
        background: #fed;
        font-weight: bold;
        font-size: 14px;
        color: $pointColor;
		@media screen and (min-width: 769px) {
			max-width: 260px;
			width: 28.5%;
		  }
      }
      td {
        background: #f5f5f5;
        margin-bottom: 2px;
        a {
          color: $pointColor;
          text-decoration: underline;
        }
      }
    }
  }
  &.tbl01 {
    tbody {
      .td {
        margin-bottom: 10px;
      }
    }
  }
}

/* 検索エリア-------------------------------------------*/

/* セレクトボックス-------------------------------------------*/

/* 検索フォーム-------------------------------------------*/
.keyword-box {
  form {
    width: 100%;
    display: flex;
    position: relative;
  }
  input[type="text"] {
    outline: none;
    border: none;
    border-radius: 25px;
    background: #fff;
    width: 100%;
    height: 50px;
    font-size: 12px;
    padding: 0 20px;
    &::placeholder {
      color: #9A9795;
      font-size: 10px;
    }
  }
  .search-submit {
    border-radius: 50%;
    width: 31px;
    height: 31px;
    border: none;
    transition: all 0.3s;
    cursor: pointer;
    background: url('/assets/images/common/icon-search.svg') no-repeat center center;
    background-size: 20px 20px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 5px;
  }
}
@media screen and (min-width: 769px) {
  .keyword-box {
    input[type="text"] {
      border-radius: 23.5px;
      height: 50px;
      font-size: 14px;
    }
  }
}


/* フロアマップパーツ-------------------------------------------*/


/* ショップ詳細共通パーツ-------------------------------------------*/


/* 過去記事のセレクター-------------------------------------------*/
.selector-wrapper {
  position: relative;
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto 20px; 
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 6%;
    right: 7%;
    margin: auto;
    left: auto;
    width: 7px;
    height: 7px;
    border-top: 2px solid $mainBlkColor;
    border-right: 2px solid $mainBlkColor;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    pointer-events: none;
  }
  select {
    background: #FFF;
    color: $mainBlkColor;
    font-size: 16px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 20px;
    border: 2px solid $mainBlkColor;
    padding: 8px;
    width: 100%;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
@media screen and (min-width:769px){
  .selector-wrapper {
    width: 185px;
    margin-left: auto;
    margin-right: 0;
    &:before {
      right: 10.5%;
    }
    select {
      padding: 10px;
      width: 100%;
      font-size: 13px;
      border-radius: 25px;
    }
  }
}

/* ブログリスト共通パーツ-------------------------------------------*/
.article-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  li {
    width: calc(100% / 2 - 8px);
    margin-right: 15px;
    margin-bottom: 20px;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease;
    &:nth-child(2n) {
      margin-right: 0;
    }
    .icon-new {
      top: -10px;
      left: -10px;
    }
    a {
      background: #fff;
      display: block;
      color: #453932;
      height: 100%;
      border-radius: 10px;
      //padding: 0 8px;
    }
    .filter-more {
      display: none;
    }
    .img-area {
      background: #fff;
      position: relative;
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      overflow: hidden;
      margin: 0;
      border-radius: 10px 10px 0 0;
      &:before {
        content: "";
        display: block;
        padding-top: 64%;
      }
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
        width: auto;
        max-height: 100%;
        height: auto;
        border: none !important;
      }
      .img-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-size: cover;
        filter: blur(10px);
        opacity: 0.6;
        overflow: hidden;
      }
    }
    .txt-area {
      padding: 7px 6px 25px;
    }
    .category {
      background: $mainBlkColor;
      border-radius: 12px;
      font-size: 11px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 10px;
      margin-bottom: 5px;
      width: fit-content;
    }
    .date {
      font-size: 11px;
      margin-bottom: 5px;
      color: #AFAFAF;
      position: relative;
      letter-spacing: 0.1em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .title {
      font-weight: bold;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 12px;
      margin-bottom: 5px;
    }
    .shopname {
      margin-bottom: 10px;
    }
    .detail {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &.noinfo {
      width: 100%;
      text-align: center;
      background: transparent;
      padding: 0 0 20px;
      margin: 0;
      justify-content: center;
    }
  }
}

@media screen and (min-width:769px){
  .article-list {
    margin: 0;
    li {
      width: calc(100% / 4 - 12px);
      margin-right: 16px;
      margin-bottom: 20px;
      &:nth-child(2n) {
        margin-right: 16px;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      .icon-new {
        width: 60px;
        height: 60px;
        font-size: 16px;
        top: -15px;
        left: -15px;
      }
      .filter-more {
        transition: all 0.3s ease;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background: rgba(245,245,245,0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        @include fontLatin;
        @include fontMedium;
        font-size: 16px;
        span {
          padding: 15px;
          width: 70%;
          text-align: center;
          font-size: 24px;
          img {
            display: block;
            margin: 8px auto 0;
          }
        }
      }
      a {
        display: block;
        position: relative;
        //padding: 0 16px;
        &:hover {
          opacity: 1;
          .filter-more {
            opacity: 1;
            display: flex;
          }
        }
      }
      .img-area {
        .icon-category {
          font-size: 14px;
        }
      }
      .txt-area {
        padding: 10px 12px 30px;
        line-height: 1.6;
      }
      .date {
        margin-left: auto;
      }
      .date-wrapper {
        display: flex;
        .scname {
          font-size: 14px;
        }
      }
      .title {
        font-size: 12px;
        -webkit-line-clamp: 2;
        margin-bottom: 20px;
      }
      // .shopname {
      // }
      .floor {
        font-size: 12px;
        padding: 3px 13px;
      }
    }
    &.blog {
      li {
        margin-bottom: 25px;
      }
    }
  }
}
.bound {
  animation: bound .3s linear;
  transform: initial;
}
@keyframes bound {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* フロアマップ・ショップリスト共通パーツ-------------------------------------------*/


/* WYSIWYG-------------------------------------------*/
.blog-detail-box {
  background: #fff;
  margin: 15px 0;
  padding: 30px 5.2vw;
  position: relative;
  border-radius: 20px;
  .ttl {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .date {
    color: #ADADAD;
    @include fontLatin;
    font-size: 16px;
    margin-bottom: 9px;
  }
  .date-box {
    border-radius: 10px;
    background: #f5f5f5;
    padding: 20px;
    margin-bottom: 20px;
    .tag {
      background: $mainBlkColor;
      border-radius: 12px;
      font-size: 11px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      display: inline-block;
      padding: 3px 10px;
      margin-bottom: 10px;
    }
    .txt {
      .date-list {
        display: flex;
        flex-wrap: wrap;
        dt {
          font-weight: bold;
          width: 15%;
          margin-right: 10px;
        }
        dd {
          width: 80%;
          min-width: 140px;
        }
      }
    }
  }
  .url {
    margin-bottom: 5px;
    a {
      text-decoration: underline;
    }
  }
  .main-txt-box {
    font-size: 12px;
  }
}
.main-txt-box {
  p {
    margin: 0 0 5px;
    line-height: 1.8;
  }
  a {
    color: $pointColor;
  }
  strong,b {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  img {
    max-width: 100%;
    margin: 15px auto;
    display: block;
    max-height: 600px;
    width: auto;
    height: auto;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  table {
    th {
      text-align: center;
      vertical-align: inherit;
      font-weight: bold;
    }
  }
  .fwN, caption, h1, h2, h3, h4, h5, h6, td, th {
    margin: 0;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }
  hr {
    display: block;
  }
}
@media screen and (min-width:769px){
  .blog-detail-box {
    margin: 0 auto 30px;
    padding: 50px 4%;
    max-width: 1090px;
    .date {
      font-size: 16px;
      margin-bottom: 9px;
    }
    .date-box {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      .tag {
        margin-right: 20px;
      }
      .txt {
        font-size: 14px;
        .date-list {
          dt {
            width: 50px;
          }
          dd {
            min-width: 200px;
            width: auto;
          }
        }
      }
    }
    .ttl {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
  .main-txt-box {
    font-size: 14px;
    .url {
      margin-bottom: 20px;
    }
  }
}

/* SNS-------------------------------------------*/
.share-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  list-style-type: none !important;
  border-radius: 10px;
  background: #f5f5f5;
  width: 80%;
  margin: 20px auto 0;
  @media screen and (min-width:769px){
    width: 270px;
    margin-top: 40px;
  }
  li {
    margin-right: 15px;
    width: 32px;
    @media screen and (min-width:769px){
      width: 36px;
    }
    img {
      margin: 0;
    }
  }
  .share-ttl {
    @include fontLatin;
    @include fontLight;
    margin-right: 30px;
    width: auto;
    font-weight: bold;
    font-size: 16px;
    @media screen and (min-width:769px){
      font-size: 20px;
    }
  }
}

/* ページャー-------------------------------------------*/
.pager-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px auto 40px;
  max-width: 1090px;
  @media screen and (min-width:769px){
    margin-top: 60px;
  }
  li {
    position: relative;
    @include fontLatin;
    @include fontLight;
    font-size: 14px;
    width: 50%;
    text-align: center;
    background: $mainBlkColor;
    border-radius: 25px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .icon-arrow02 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      &::before {
        border-color: #fff;
        border-width: 2px;
        width: 10px;
        height: 10px;
      }
    }
  }
  .btn-back,
  .btn-next {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .btn-back {
    margin-right: auto;
    @media screen and (min-width:769px){
      margin-right: 40px;
    }
    .icon-arrow02 {
      transform: rotate(180deg);
      left: 4px;
      &::before {
      }
    }
  }
  .btn-next {
    margin-left: auto;
    @media screen and (min-width:769px){
      margin-left: 40px;
    }
    .icon-arrow02 {
      right: 4px;
    }
  }
  .btn-top {
    width: 200px;
    font-weight: bold;
    font-size: 20px;
    @include fontLatin;
    padding: 10px;
    @media screen and (min-width:769px){
    }
  }
  .disable {
    opacity: 0;
    visibility: hidden;
  }
}


/* 右下のPAGETOPボタン-------------------------------------------*/
.btn-pagetop {
  cursor: pointer;
  transition: 0.3s;
  position: fixed;
  bottom: 47px;
  right: 2%;
  z-index: 9;
  display: block !important;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: $mainBlkColor;
  border-radius: 50%;
  &::before {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 6px;
    height: 6px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(315deg);
  }
}
.btn-hide {
  opacity: 0;
  visibility: hidden;
  transition: all  0.3s;
  &:hover {
    visibility: hidden;
  }
}
@media screen and (min-width:769px){
  .btn-pagetop {
    width: 70px;
    height: 70px;
    bottom: 93px;
    &::before {
      top: 6px;
      width: 10px;
      height: 10px;
    }
    &:hover {
      background: $mainBlkColor;
      opacity: 0.7;
      &::before {
        border-color: #fff;
      }
    }
  }
  .btn-hide {
    opacity: 0;
    visibility: hidden;
    transition: all  0.3s;
  }
}

/* フッターバナー-------------------------------------------*/
.bnr-area {
  overflow: hidden;
  .bnr-list {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 20px 25px;
    li {
      width: calc(100% / 2 - 5px);
      margin-right: 10px;
      margin-bottom: 4px;
      text-align: center;
      background: #fff;
      overflow: hidden;
      &:nth-child(2n) {
        margin-right: 0;
      }
      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 100%;
        z-index: 0;
        &::before {
          content: '';
          background: inherit;
          filter: blur(10px);
          position: absolute;
          top: -10px;
          left: -10px;
          right: -10px;
          bottom: -10px;
          z-index: -1;
        }
        img {}
      }
    }
  }
}
@media screen and (min-width:769px){
  .bnr-area {
    padding: 40px 20px;
    .bnr-list {
      max-width: 900px;
      margin: 0 auto;
      padding: 0;
      li {
        width: calc(100% / 3 - 14px);
        margin-right: 20px;
        margin-bottom: 15px;
        &:nth-child(2n) {
          margin-right: 20px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

/* フッター-------------------------------------------*/
.ft-scinfo {
  .txt-box {
    border-radius: 60px 0px 0px 0px;
    background: rgba(255,238,221,0.8);  
    padding: 55px 20px 40px;
    margin-top: -30px;
    position: relative;
    z-index: 1;
    text-align: center;
    .sc-name {
      margin-bottom: 20px;
      img {
        width: 47%;
      }
    }
    .sc-info {
      margin-bottom: 20px;
      font-size: 14px;
    }
  }
  .map-box {
    iframe {
      width: 100%;
      height: 375px;
    }
  }
  .btn-more {
    font-size: 16px;
  }
}
@media screen and (min-width:769px){
  .ft-scinfo {
    position: relative;
    .txt-box {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-top: 0;
      width: 371px;
      height: 412px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .sc-name {
        img {
        }
      }
      .sc-info {
      }
    }
    .map-box {
      height: 412px;
      iframe {
        width: 100%;
        height: 412px;
      }
    }
  }
}

.cmn-ft {
  .copyright {
    text-align: center;
    font-size: 11px;
    color: #fff;
    background: $mainBlkColor;
    padding: 10px 20px;
    margin: 0;
  }
}
@media screen and (min-width:769px){
  .cmn-ft {
    .copyright {
      padding: 30px;
      max-width: 100%;
    }
  }
}

.ft-area {
  background: #F8F8F8;
  padding: 50px 0 25px;
}
@media screen and (min-width:769px){
  .ft-area {
   padding: 50px 0 70px;
  }
}

